<template>
  <div ref="container" class="container"></div>
</template>

<script>
export default {
  name: 'FlvPlayer',
  props: {
    id: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      videoPlayer: null,
      flvUrl: null,
      rePlayTimes: 0,
      maxReplayTimes: 10
    }
  },
  mounted () {
    this.initPlayer()
  },
  beforeDestroy () {
    if (this.videoPlayer) {
      try {
        this.videoPlayer.dispose()
      } catch (e) {
        console.log(e)
      }
    }
  },
  methods: {
    initPlayer () {

    },
    play (flvSource) {
      if (this.videoPlayer) {
        try {
          this.videoPlayer.dispose()
        } catch (e) {
          console.log(e)
        }
      } else {
        this.rePlayTimes = 0
      }

      this.flvUrl = flvSource
      const closeButton = videojs.getComponent('Button')
      const _this = this
      const MyButton = videojs.extend(closeButton, {
        constructor: function () {
          closeButton.apply(this, arguments)
          this.controlText('关闭')
        },
        handleClick: function () {
          _this.destroyPlayer()
        },
        buildCSSClass: function () {
          return 'vjs-icon-closebutton vjs-control vjs-button'
        }
      })
      videojs.registerComponent('MyButton', MyButton)

      const videoId = 'videoId_' + Date.now()
      const videoHtml = '<video id=' + videoId + ' class="video-js vjs-default-skin vjs-big-play-centered" style="object-fit: fill;width: 100%;height: 100%"  controls preload="auto" autoplay="autoplay" muted="muted" width="100%" height="100%" poster="/static/images/video/video-cover.png"></video>'
      this.$refs.container.innerHTML = videoHtml

      const options = {
        poster: '/static/images/video/video-cover.png',
        autoplay: true,
        fill: true,
        liveui: true,
        language: 'zh-CN',
        flvjs: {
          mediaDataSource: {
            isLive: true,
            cors: true,
            withCredentials: false,
            lazyLoad: false,
            enableStashBuffer: false,
            enableWorker: true,
            stashInitialSize: 1,
            // hasVideo:true,
            // hasAudio:true,
            fixAudioTimestampGap: false
          }
        },
        controlBar: {
          progressControl: false,
          remainingTimeDisplay: false,
          volumePanel: {
            inline: false
          },
          playToggle: false
        }
      }

      this.videoPlayer = videojs(videoId, options, function onPlayerReady () {
        this.src([
          { type: 'video/x-flv', src: flvSource } // Flv
        ])
        this.getChild('controlBar').addChild('myButton', {})
        this.load()
        this.play()
      })
      this.videoPlayer.on('loadstart', function (e) {
        const flvPlayer = this.tech_.flvPlayer
        this.play()
        setTimeout(() => {
          this.play()
        }, 500)
        const _this_ = _this
        flvPlayer.on(flvjs.Events.ERROR, function (e) {
          console.log(e)
          if (e === 'NetworkError') {
            _this_.showReplayBtn()
          }
          if (e === 'MediaError') {
            if (_this_.rePlayTimes >= _this_.maxReplayTimes) {
              _this_.showReplayBtn()
              return
            }
            setTimeout(() => {
              _this_.play(_this.flvUrl)
              _this_.rePlayTimes++
            }, 500)
          }
        })

        flvPlayer.on(flvjs.Events.LOADING_COMPLETE, () => {
          _this_.showReplayBtn()
        })
      })
    },
    showReplayBtn () {
      const reloadImg = require('@/assets/imgs/monitor/play.png')
      if (this.videoPlayer) {
        try {
          this.videoPlayer.dispose()
          this.$refs.container.innerHTML = ''
          const div = document.createElement('div')
          div.classList.add('replayend-display')
          div.innerHTML = `<img src="${reloadImg}" style="width: 24px;height: 24px " title="重新播放">`
          this.$refs.container.appendChild(div)
          div.onclick = () => {
            this.play(this.flvUrl)
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    destroyPlayer () {
      if (this.videoPlayer) {
        try {
          this.videoPlayer.dispose()
          this.videoPlayer = null
        } catch (e) {
          console.log(e)
        }
      }
      this.$refs.container.innerHTML = ''
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  background-color: #000000;
  margin: 0 auto;
  position: relative;
  background-image: url("~@/assets/imgs/monitor/video_cover.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

</style>

<style>
.replayend-display {
    vertical-align: center;
    font-size: 12px;
    position: absolute;
    top: calc(50% - 19px);
    left: calc(50% - 38px);
    cursor: pointer;
    border: 1px solid lightgray;
    padding: 5px 25px;
    background: rgba(30, 51, 94, 0.8);
    border-radius: 10px;
}

.replayend-display:hover {
    background: rgba(30, 51, 94, 1);
}
</style>
