import http from '@/utils/http'

// 场地视频树
// export function getVideoTree(p) {
//   return http.post('/unibu/monitor/vehi/getVediTree', p)
// }
export function getVideoTree (p) {
  return http.post('/unibu/monitor/vehi/getFieldTree', p)
}

// 获取通道列表
// deviceId: string
export function getDeviceChannelList (p) {
  return http.get('/basefun/camera1cs/proxy/api/device/query/getDeviceChannelList', p)
}

// 获取视频
export function getPlaceVideoUrl (p) {
  return http.get(`/basefun/camera1cs/proxy/api/play/start/${p?.deviceId}/${p.channelId}`)
}

// 获取视频
// export function getVideoUrl (p) {
//   return http.get('http://172.16.0.78:49501/basefun/camera1cs/proxy/api/media/stream_info_by_app_and_stream', p)
// }
